@import './../../style/abstracts/mixins';

.enquiry_sent_wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
}

.enquiry_sent {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 400px;

    @include respond(phone) {
        width: 100%;
    }

    &_icon {
        margin-bottom: 20px;
    }

    &_content {

        h3 {
            font-size: 17px;
            margin-bottom: 20px;
            color: #325081;
            text-align: center;

        }

        p {
            color: #325081;
            font-size: 16px;
            text-align: center;
        }
    }

    &_action {
        margin-top: 40px;
        display: flex;
        width: 100%;
        align-items: center;
        justify-content: center;

        input[type='submit'] {
            padding: 10px;
            border: none;
            border-radius: 6px;
            background: #002661;
            color: #fff;
            font-family: 'DM Sans';
            width: 100%;
            font-size: 15px;
            width: 200px;
            cursor: pointer;

            @include respond(phone) {
                margin-bottom: 20px;
            }
        }
    }
}