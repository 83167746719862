@import './../../style/abstracts/mixins';

.enquiries_item {
    display: flex;
    flex-direction: column;
    width: 313px;
    border-radius: 10px;
    box-shadow: -5px 4px 15px 3px rgba(0, 0, 0, 0.1);
    padding: 20px;
    margin-right: 20px;
    margin-top: 20px;
    justify-content: space-between;

    @include respond(phone) {
        width: 100%;
    }

    &_pickup {
        display: flex;
        align-items: flex-start;
        margin-bottom: 20px;
        justify-content: space-between;

        &_loc {
            width: 57%;
        }

        &_status {
            display: flex;
            width: 42%;
            justify-content: flex-end;

            p {
                width: 80%;
                padding: 5px 10px;
                border-radius: 10px;
                background: #D7F2DF;
                color: #47C56C;
                text-align: center;
            }
        }
    }

    &_drop {
        display: flex;
        margin-bottom: 20px;
        flex-direction: column;
    }

    &_pdtime {
        display: flex;
        margin-bottom: 20px;
        justify-content: space-between;


        &_pick {
            width: 48%;
        }

        &_drop {
            width: 48%;
        }
    }

    &_pr {
        display: flex;
        margin-bottom: 20px;
        justify-content: space-between;

        &_p {
            width: 48%;
        }

        &_r {
            width: 48%;
        }
    }

    &_action {
        display: flex;
        justify-content: center;
        align-items: center;

        input[type='submit'] {
            padding: 10px;
            width: 100%;
            background: #10356D;
            border: none;
            outline: none;
            cursor: pointer;
            color: #fff;
            border-radius: 7px;
            font-family: 'DM Sans';
        }
    }
}