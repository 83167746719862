@import './../../style/abstracts/mixins';

.offers_cards_item {
    display: flex;
    align-items: center;
    margin-top: 10px;
    margin-right: 20px;
    cursor: pointer;

    @include respond(phone) {
        height: 50px;
    }



    &_pos {
        @include respond(phone) {
            display: none;
        }

        p {
            font-size: 25px;
            font-weight: bold;
            margin-right: 20px;
        }
    }

    &_cont {
        display: flex;
        align-items: center;
        box-shadow: -5px 4px 15px 3px rgba(0, 0, 0, 0.1);
        border-radius: 10px;
        padding: 10px;
        width: 100%;
        margin-bottom: 10px;
        background: #fff;

        @include respond(phone) {
            height: 50px;
            width: 190px;
        }


        .business_info {

            p {
                margin: 0;
            }

            .business_name {
                font-size: 18px;
                white-space: nowrap;
                overflow: hidden;
            }
        }
    }

}