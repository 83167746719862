@import './../../style/abstracts/mixins';


.loading_section,
.notfound_section {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    p {
        text-align: center;
    }
}

.banner_section {
    margin-top: -20px;
    width: 100%;

    .banner_image {
        img.bannerimg {
            width: 100%;
            height: 250px;
            object-fit: cover;
            border-radius: 10px;
            object-position: center;

            @include respond(phone) {
                height: 150px;
            }
        }
    }

    .banner_description {
        display: flex;
        flex-direction: column;
        margin-top: -40px;


        &_logo {
            display: flex;
            justify-content: space-between;
            align-items: flex-end;
            margin-bottom: 20px;


            &-left {
                display: flex;
                align-items: flex-end;
                margin-left: 25px;

                @include respond(phone) {
                    flex-direction: column;
                    align-items: flex-start;
                    margin-left: 0px;
                }

                img {
                    width: 80px;
                    height: 80px;
                    border-radius: 100px;
                    border: 5px solid #fff;
                    margin-right: 10px;
                    background-color: #F3F8FF;

                    @include respond(phone) {
                        width: 60px;
                        height: 60px;
                        margin-left: 15px;
                    }
                }

                .brand_desc {
                    h2 {
                        margin: 0px;
                    }
                }
            }

            &-right {
                margin-bottom: 10px;

                a.booking_form {
                    color: #002661;
                    border: 1px solid #002661;
                    border-radius: 5px;
                    padding: 7px 10px;
                    cursor: pointer;

                    @include respond(phone) {
                        padding: 5px 7px;
                    }
                }
            }
        }

        &_text {
            text-align: justify;

            p {
                display: inline;
            }

            .see-more {
                color: #002661;
                cursor: pointer;
                font-weight: bold;
                margin-left: 5px;
            }

            .see-more:hover {
                text-decoration: underline;
            }
        }
    }
}

.menu_section {
    position: -webkit-sticky;
    position: sticky;
    top: 0;
    z-index: 1000;
    transition: box-shadow 0.3s ease;

    &.sticky {
        background-color: #F3F8FF;
    }

    .business_page_nav {
        margin-top: 10px;
        border-top: 1px solid #E6F2FE;
        border-bottom: 1px solid #E6F2FE;

        ul {
            display: flex;
            margin-left: 0px;

            li {

                list-style-type: none;
                margin-right: 20px;
                width: 70px;
                display: flex;
                justify-content: center;
                align-items: center;
                padding: 15px 0px;

                a {
                    color: #717171;
                    cursor: pointer;

                    &.active {
                        color: #002661;
                    }
                }

                &.active {
                    border-bottom: 3px solid #002661;
                }
            }


        }
    }
}



.image_section {
    width: 100%;
    margin-top: 30px;

    .slider_images {
        width: 100%;
        display: flex;
        overflow-x: auto;
        scroll-behavior: smooth;
        padding-bottom: 10px;


        &_item {
            width: 170px;
            min-width: 170px;
            height: 260px;
            margin-right: 10px;
            border-radius: 10px;

            @include respond(phone) {
                width: 90px;
                min-width: 90px;
                height: 150px;
            }

            img {
                width: 100%;
                height: 100%;
                object-fit: cover;
                border-radius: 10px;
                object-position: center;
            }
        }
    }


}

.form_section {
    margin-top: 50px;

    @include respond(phone) {
        margin-top: 30px;
    }

    .bp_booking_form_section {
        display: flex;
        width: 100%;
        justify-content: space-between;
        margin-bottom: 50px;

        @include respond(phone) {
            flex-direction: column;
            align-items: center;
        }

        &_form {
            width: 50%;
            display: flex;
            justify-content: flex-start;
            align-items: center;

            @include respond(phone) {
                width: 100%;
            }

            &_content {
                display: flex;
                flex-direction: column;
                width: 90%;
                background: #fff;
                border-radius: 10px;
                padding: 20px;
                box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);

                @include respond(phone) {}

                &_greet {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;
                    width: 100%;
                    margin-bottom: 20px;


                    &>* {
                        display: flex;
                        justify-content: center;
                        align-items: center;
                        width: 50%;
                        cursor: pointer;
                    }


                }

                &_way {

                    span:last-child {
                        color: #002661;
                    }
                }

                &_linp {
                    display: flex;
                    flex-direction: column;

                    >* {
                        margin: 10px 0;
                    }

                    input[type='text'] {
                        padding: 10px;
                        border: none;
                        outline: none;
                        border: 1px solid #DBDBDB;
                        font-family: 'DM Sans';
                        color: #4E6776;
                        border-radius: 6px;
                    }


                }

                &_drdt {
                    display: flex;
                    width: 100%;
                    justify-content: space-between;
                    flex-direction: column;

                    label {
                        top: -8px;
                    }

                    >* {
                        margin: 10px 0;
                        width: 100%;
                    }

                    input[type='text'] {
                        padding: 8.5px 10px;
                    }
                }

                &_vehicle {
                    margin: 10px 0;

                    fieldset {
                        outline: none !important;
                    }

                    #vehilce-select {
                        padding: 8.5px 10px;
                        padding-right: 30px;
                    }

                    >* {
                        width: 100%;
                    }
                }

                &_pr,
                &_vl {
                    display: flex;
                    width: 100%;
                    justify-content: space-between;

                    >* {
                        margin: 10px 0;
                        width: 48%;
                    }

                    input[type='text'] {
                        padding: 8.5px 10px;
                    }

                    input[type='number'] {
                        padding: 8.5px 10px;
                    }

                    label {
                        top: -8px;
                    }

                    fieldset {
                        outline: none !important;
                    }

                    #travelling-reason-select,
                    #luggage-select {
                        padding: 8.5px 10px;
                        padding-right: 30px;
                    }

                    @include respond(phone) {
                        flex-direction: column;

                        >* {
                            width: 100%;
                        }

                    }

                }

                &_message {
                    width: 100%;
                    margin-top: 20px;

                    textarea {
                        font-family: 'DM Sans';
                        padding: 10px;
                        border: 1px solid #DBDBDB;
                        border-radius: 6px;
                        width: 95%;
                        font-size: 15px;

                        @include respond(phone) {
                            width: 92%;
                        }

                        &:focus {
                            outline: none;
                        }
                    }
                }

                &_action {
                    display: flex;
                    width: 100%;
                    justify-content: center;
                    align-items: center;
                    margin-top: 20px;
                }


                &_cusname,
                &_cusemail,
                &_cusphone {
                    display: flex;
                    width: 100%;
                    justify-content: space-between;
                    margin-top: 20px;

                    &>* {
                        width: 100%;
                    }


                    input[type='text'] {
                        padding: 8.5px 10px;
                    }

                    input[type='email'] {
                        padding: 8.5px 10px;
                    }

                    input[type='number'] {
                        padding: 8.5px 10px;
                    }

                    input[type='tel'] {
                        padding: 8.5px 10px;
                    }

                    label {
                        top: -8px;
                    }

                    fieldset {
                        outline: none !important;
                    }

                }

                &_emailaction {
                    margin-top: 5px;
                    display: flex;
                    justify-content: flex-end;

                    &>* {
                        margin-left: 10px;
                    }

                    input[type='submit'] {
                        padding: 5px;
                        border-radius: 3px;
                        background: #47C56C;
                        width: 100%;
                        font-size: 13px;
                        width: 100px;
                    }

                    &> :first-child input[type='submit'] {
                        background: #e16a94;
                    }
                }
            }
        }


        &_ad {
            width: 45%;
            display: flex;
            justify-content: center;
            flex-direction: column;

            @include respond(phone) {
                width: 100%;
                margin-top: 60px;
                align-items: center;
            }

            img {
                width: 100%;
                border-radius: 10px;
                margin-top: 15px;
                height: 300px;
                object-fit: cover;

                @include respond(phone) {
                    width: 90%;
                    height: 200px;
                }
            }

            h1 {
                font-size: 40px;
                color: #002661;
                margin-bottom: 20px;
                text-transform: uppercase;

                @include respond(phone) {
                    font-size: 40px;
                }
            }

            p {
                font-size: 18px;

                @include respond(phone) {
                    font-size: 16px;
                    text-align: center;
                }
            }
        }
    }


}

.review_section {
    margin-top: 30px;

    .bp_review_section {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;


        &_items {
            display: flex;
            flex-wrap: wrap;
            align-self: center;
            justify-content: space-between;
            margin-top: 20px;

            @include respond(phone) {
                justify-content: center;
            }
        }

        &_action {
            margin-top: 50px;

            input[type='submit'] {
                padding: 10px;
                border: none;
                border-radius: 6px;
                background: #47C56C;
                color: #fff;
                font-family: 'DM Sans';
                width: 100%;
                font-size: 15px;
                width: 250px;
                cursor: pointer;
            }
        }
    }
}

.location_section {
    margin-top: 30px;
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: center;
    align-items: center;


    &_desc {
        text-align: center;
    }

    &_content {
        display: flex;
        width: 100%;
        margin-top: 20px;
        justify-content: space-between;

        @include respond(phone) {
            flex-direction: column;
        }

        &-map {
            width: 67%;
            height: 450px;
            border-radius: 10px;
            overflow: hidden;

            @include respond(phone) {
                width: 100%;
                height: 350px;
            }
        }

        &-wh {
            display: flex;
            flex-direction: column;
            width: 30%;
            height: 450px;
            justify-content: center;
            align-items: flex-start;

            @include respond(phone) {
                margin-top: 20px;
                width: 100%;
            }



            table {
                width: 100%;

                td {
                    padding: 4px;
                    padding-left: 0px;
                }
            }

            .working_hours {
                h2 {
                    margin-top: 0px;
                }
            }

            .contact_details {}
        }
    }
}