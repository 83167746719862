@import './../../style/abstracts/mixins';

.home_page_content_wrapper {

    .home_enquiry_section {
        display: flex;
        width: 100%;
        justify-content: space-between;
        margin-bottom: 50px;

        @include respond(phone) {
            flex-direction: column-reverse;
            align-items: center;
        }

        &_form {
            width: 50%;
            display: flex;
            justify-content: center;
            align-items: center;

            @include respond(phone) {
                width: 100%;
            }

            &_content {
                display: flex;
                flex-direction: column;
                width: 80%;
                background: #fff;
                border-radius: 10px;
                padding: 20px;
                box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);

                @include respond(phone) {}

                &_greet {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    flex-direction: column;

                    .enq_home_display {
                        display: none;

                        @include respond(phone) {
                            display: block;
                        }
                    }
                }

                &_way {

                    span:last-child {
                        color: #002661;
                    }
                }

                &_linp {
                    display: flex;
                    flex-direction: column;

                    >* {
                        margin: 10px 0;
                    }

                    input[type='text'] {
                        padding: 10px;
                        border: none;
                        outline: none;
                        border: 1px solid #DBDBDB;
                        font-family: 'DM Sans';
                        color: #4E6776;
                        border-radius: 6px;
                    }


                }

                &_drdt {
                    display: flex;
                    width: 100%;
                    justify-content: space-between;
                    flex-direction: column;

                    label {
                        top: -8px;
                    }

                    >* {
                        margin: 10px 0;
                        width: 100%;
                    }

                    input[type='text'] {
                        padding: 8.5px 10px;
                    }
                }

                &_vehicle {
                    margin: 10px 0;

                    fieldset {
                        outline: none !important;
                    }

                    #vehilce-select {
                        padding: 8.5px 10px;
                        padding-right: 30px;
                    }

                    >* {
                        width: 100%;
                    }
                }

                &_pr,
                &_vl {
                    display: flex;
                    width: 100%;
                    justify-content: space-between;

                    >* {
                        margin: 10px 0;
                        width: 48%;
                    }

                    input[type='text'] {
                        padding: 8.5px 10px;
                    }

                    input[type='number'] {
                        padding: 8.5px 10px;
                    }

                    label {
                        top: -8px;
                    }

                    fieldset {
                        outline: none !important;
                    }

                    #travelling-reason-select,
                    #luggage-select {
                        padding: 8.5px 10px;
                        padding-right: 30px;
                    }

                    @include respond(phone) {
                        flex-direction: column;

                        >* {
                            width: 100%;
                        }

                    }

                }

                &_message {
                    width: 100%;
                    margin-top: 20px;

                    textarea {
                        font-family: 'DM Sans';
                        padding: 10px;
                        border: 1px solid #DBDBDB;
                        border-radius: 6px;
                        width: 95%;
                        font-size: 15px;

                        @include respond(phone) {
                            width: 92%;
                        }

                        &:focus {
                            outline: none;
                        }
                    }
                }

                &_action {
                    display: flex;
                    width: 100%;
                    justify-content: center;
                    align-items: center;
                    margin-top: 20px;
                }
            }
        }


        &_ad {
            width: 43%;
            display: flex;
            justify-content: center;
            flex-direction: column;
            padding: 20px;
            align-items: center;

            @include respond(phone) {
                width: 90%;
            }

            img {
                width: 100%;

                @include respond(phone) {
                    width: 80%;
                }
            }

            h1 {
                font-size: 52px;
                color: #002661;
                margin-bottom: 20px;
                text-transform: uppercase;

                @include respond(phone) {
                    font-size: 40px;
                }
            }

            p {
                font-size: 18px;
            }
        }
    }

    .home_testimonial_section {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;


        &_items {
            display: flex;
            flex-wrap: wrap;
            align-self: center;
            justify-content: space-between;
            margin-top: 20px;

            @include respond(phone) {
                justify-content: center;
            }
        }

        &_action {
            margin-top: 50px;

            input[type='submit'] {
                padding: 10px;
                border: none;
                border-radius: 6px;
                background: #002661;
                color: #fff;
                font-family: 'DM Sans';
                width: 100%;
                font-size: 15px;
                width: 250px;
                cursor: pointer;
            }
        }
    }


}