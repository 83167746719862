@import './../../style/abstracts/mixins';



.testimonials_cards {
    display: flex;
    flex-wrap: wrap;

    @include respond(phone) {
        justify-content: center;
    }
}

.testimonials_action_wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 80px;
}