@import './../../style/abstracts/mixins';

.container {
    max-width: 1120px;
    margin: 0 auto;
    padding: 0 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    height: 100%;

    @include respond(phone) {
        flex-direction: row-reverse;
    }
}

.navbar {
    height: 80px;
    background-color: #F3F8FF;
    position: relative;

    @include respond(phone) {
        height: 60px;
    }
}

.logo {

    .logo_desk {
        @include respond(phone) {
            display: none;
        }
    }

    .logo_mob {
        display: none;

        @include respond(phone) {
            display: block;
        }
    }
}

.menu-icon {
    display: none;

    @include respond(phone) {
        display: block;
        cursor: pointer;
    }
}

.nav-elements {

    @include respond(phone) {
        position: absolute;
        left: 0;
        top: 60px;
        background-color: #F3F8FF;
        width: 0px;
        height: calc(100vh - 60px);
        transition: all 0.3s ease-in;
        overflow: hidden;

    }

    &.active {
        @include respond(phone) {
            width: 70%;
            padding: 10px 20px;
            box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
        }
    }
}

.nav-elements ul {
    display: flex;
    justify-content: space-between;
    list-style-type: none;

    @include respond(phone) {
        display: flex;
        flex-direction: column;
    }
}

.nav-elements ul li {

    @include respond(phone) {
        margin-right: unset;
        margin-top: 22px;
        width: 100%;
    }
}

.nav-elements ul li:not(:last-child) {
    margin-right: 60px;

    @include respond(tab-port) {
        margin-right: 30px;
    }
}

.nav-elements ul a {
    font-size: 16px;
    font-weight: 400;
    color: #2f234f;
    text-decoration: none;
}

.nav-elements ul a.active {
    color: #574c4c;
    font-weight: 500;
    position: relative;
}

.nav-elements ul a.active::after {
    content: "";
    position: absolute;
    bottom: -4px;
    left: 0;
    width: 100%;
    height: 2px;
    background-color: #574c4c;
}