@import './../../style/abstracts/mixins';




.payment_page_content_wrapper {

    .payment_page_section {
        display: flex;
        width: 100%;
        justify-content: space-between;
        margin-bottom: 50px;

        @include respond(phone) {
            flex-direction: column;
            align-items: center;
        }

        &_ad {
            width: 50%;
            display: flex;
            justify-content: center;
            flex-direction: column;
            padding: 20px;

            @include respond(phone) {
                width: 90%;
                align-items: center;
            }

            img {
                width: 100%;
                width: 350px;

                @include respond(phone) {
                    width: 80%;
                }
            }

            h1 {
                font-size: 20px;
                color: #324A59;
                margin-bottom: 20px;
                text-transform: uppercase;

                @include respond(phone) {
                    font-size: 17px;
                }
            }

            p {
                font-size: 16px;
                color: #002661;

                @include respond(phone) {
                    text-align: center;
                }
            }

            &-location {

                span {
                    font-weight: bold;
                    color: #002661;
                }
            }

            &-amount {
                font-weight: bold;
                font-size: 40px !important;
                margin-top: 10px;
            }

            &-amount_note {
                margin-bottom: 5px;
                font-style: italic;
                font-size: 15px !important;
                margin-bottom: 10px;
            }

            &-type {
                margin-bottom: 5px;
            }

            &-date {
                font-weight: bold;
            }
        }

        &_form {
            width: 50%;
            display: flex;
            justify-content: center;
            align-items: flex-end;
            flex-direction: column;

            @include respond(phone) {
                width: 100%;
                justify-content: center;
                align-items: center;
            }
        }
    }
}