@import './../../style/abstracts/mixins';

.about_us_img {
    display: flex;
    justify-content: space-between;
    align-items: center;

    @include respond(phone) {
        flex-direction: column;
    }

    &_left {
        width: 60%;

        @include respond(phone) {
            width: 100%;
        }
    }

    &_right {
        width: 40%;
        display: flex;
        justify-content: flex-end;


        @include respond(phone) {
            width: 100%;
            justify-content: center;
            margin: 15px 0;
        }
    }
}