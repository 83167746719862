.MuiDialogContent-root {
    label {
        top: -8px;
    }

    input[type='email'] {
        padding: 8.5px 10px;
    }

    input[type='text'] {
        padding: 8.5px 10px;
    }
}