@import './../../style/abstracts/mixins';

.bp_footer_wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    background-color: #002661;

    p {
        color: #fff;
    }
}

.bp_footer_content {
    width: 1120px;
    max-width: 1120px;
    padding: 0 15px;


    .bp_copyright_content {
        display: flex;
        align-items: center;
        justify-content: space-between;

        padding: 20px 0;
    }
}