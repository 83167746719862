@import './../../style/abstracts/mixins';


.bpenquiry_details_wrapper {
    width: 100%;
    display: flex;
    flex-direction: column;
}

.bpenquiry_header {
    display: flex;
    width: 100%;
    justify-content: space-between;
    background: #002661;
    border-radius: 3px;
    padding: 20px 0;
    align-items: center;

    h4,
    p {
        color: #fff;
    }

    @include respond(phone) {
        align-items: flex-start;
    }

    &_left {
        display: flex;
        justify-content: space-between;
        width: 55%;
        padding: 10px;
        align-items: center;

        @include respond(phone) {
            flex-direction: column;
            width: 100%;

        }

        &_pickup {
            margin-right: 20px;
            width: 40%;

            @include respond(phone) {
                width: 100%;
                margin-right: 0px;

            }
        }

        &_car {
            display: flex;
            justify-content: center;
            align-items: center;
            flex-direction: column;
            margin-right: 20px;
            width: 20%;

            @include respond(phone) {
                display: none;
            }

            .car_loading {}

            .car_dots {
                display: flex;
                justify-content: center;
                align-items: center;

                .left_dot {
                    width: 10px;
                    height: 10px;
                    border-radius: 100px;
                    border: 1px solid #fff;
                }

                .dashed_line {
                    height: 1;
                    width: 100px;
                    border: 0.8px dashed #fff;
                }

                .right_dot {
                    width: 10px;
                    height: 10px;
                    border-radius: 100px;
                    border: 1px solid #fff;
                }
            }
        }

        &_drop {
            width: 40%;

            @include respond(phone) {
                margin-top: 15px;
                width: 100%;
            }
        }

        &_rft {
            display: none;

            @include respond(phone) {
                display: block;
                margin-top: 15px;
                width: 100%;
            }
        }
    }

    &_right {
        display: flex;
        justify-content: space-between;
        width: 45%;
        padding: 10px;

        @include respond(phone) {
            flex-direction: column;

            width: 100%;
        }

        &_pickupdate {}

        &_dropdate {
            @include respond(phone) {
                margin-top: 15px;
            }
        }

        &_pass {
            @include respond(phone) {
                margin-top: 15px;
            }
        }
    }
}

.bpenquiry_details {
    display: flex;
    justify-content: space-between;


    @include respond(phone) {
        flex-direction: column;
    }

    &_left {
        width: 25%;
        padding: 10px;
        margin-bottom: -50px;

        @include respond(phone) {
            width: 320px;
            margin: 10px 0;
            padding: 0px;
        }

        .offers_cards {
            width: 100%;
            display: flex;
            flex-direction: column;
            height: 600px;
            overflow-y: scroll;

            @include respond(phone) {
                flex-direction: row;
                height: 70px;
                padding: 20px;
                padding-left: 15px;
                overflow-y: hidden;
            }



        }
    }



    &_right {
        display: flex;
        width: 72%;
        height: 750px;
        flex-direction: column;


        @include respond(phone) {
            width: 100%;
            height: 800px;

        }

        .message_alert {
            display: flex;
            flex-direction: row;
            align-items: center;
            justify-content: center;
            cursor: pointer;

            p {
                color: red;
            }

        }

        &_chat {
            height: 550px;
            background: #F3F8FF;
            margin: 20px;
            margin-top: 10px;
            border: 2px solid #D6E7FF;
            border-radius: 10px;
            margin-right: 0;
            display: flex;
            flex-direction: column;
            justify-content: flex-start;

            @include respond(phone) {
                margin: 0px;
                margin-top: 10px;

            }

            &--c {
                display: flex;
                flex-direction: column;
                justify-content: flex-start;
                overflow-y: scroll;


                .alert_text {
                    width: calc(100% - 40px);
                    justify-content: center;
                    padding: 0 20px;
                    align-items: center;
                    margin-bottom: 10px;

                    p {
                        text-align: center;
                    }
                }

            }

            &_left {
                width: 60%;
                display: flex;
                justify-content: flex-start;
                align-items: flex-end;
                padding: 10px;

                pre {
                    white-space: pre-wrap;
                    white-space: -moz-pre-wrap;
                    white-space: -o-pre-wrap;
                    word-wrap: break-word;
                    font-family: 'DM Sans';
                    color: #002661;
                }

                .company_info {
                    p {
                        font-family: "DM Sans";
                        margin: 5px 0;
                    }
                }

                @include respond(phone) {
                    width: 80%;
                }

                .p_method_wrapper {
                    display: flex;
                    flex-wrap: wrap;

                    .p_method {
                        padding: 5px 10px;
                        border: 1px solid #47C56C;
                        margin-right: 10px;
                        border-radius: 5px;
                        margin-top: 10px;

                    }
                }

                .review_company_det {
                    display: flex;
                    flex-direction: row;
                }

                .review_company_rev {
                    margin-top: 10px;
                    margin-bottom: 10px;
                }

                .review_company_stars {
                    display: flex;
                    justify-content: space-between;
                    margin-top: 15px;

                    .rating_star {
                        display: flex;
                    }

                    .rating_status {
                        margin-left: 10px;
                    }

                    .rating_action {
                        display: flex;
                        align-items: center;
                        cursor: pointer;

                        p {
                            color: #47C56C;
                            margin-right: 5px;
                        }
                    }
                }

                .vehicle_content {
                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    p {
                        width: 85%;
                    }

                    .vehicle_image {
                        width: 25%;
                        cursor: pointer;
                        position: relative;

                        img {
                            width: 100%;
                            height: 60px;
                            object-fit: cover;
                            transition: .5s ease;
                            background-color: #000;

                            @include respond(phone) {
                                height: 50px;
                            }

                        }



                        .zoom_image {
                            transition: .5s ease;
                            opacity: 0;
                            position: absolute;
                            top: 50%;
                            left: 50%;
                            transform: translate(-50%, -50%);
                            -ms-transform: translate(-50%, -50%);
                            text-align: center;
                            cursor: pointer;
                        }

                        &:hover .zoom_image {
                            opacity: 1;
                        }

                        &:hover img {
                            opacity: 0.5;
                        }
                    }
                }


                .avater_wrapper {}

                .message_wrapper {
                    background: #fff;
                    padding: 10px;
                    border-radius: 10px;
                    border-bottom-left-radius: 0;
                }
            }

            &_right {
                width: 50%;
                background: #D3E4FF;
                padding: 10px;
                margin: 10px;
                border-radius: 10px;
                align-self: flex-end;
                border-bottom-right-radius: 0;

                p {
                    color: #002661;
                }

                @include respond(phone) {
                    width: 80%;
                }
            }

            &--i {
                width: 100%;
                display: flex;
                flex-direction: row;
                align-items: center;
                justify-content: center;
                margin-bottom: 10px;
                padding: 0 10px;

                @include respond(phone) {
                    width: 90%;
                }


                &_inp {
                    width: 80%;
                    margin-right: 40px;
                    padding-top: 10px;

                    @include respond(phone) {
                        margin-right: 30px;
                    }

                    textarea {
                        width: 100%;
                        border: none;
                        border-radius: 5px;
                        font-family: inherit;
                        padding: 10px;
                        box-shadow: -5px 4px 15px 3px rgba(0, 0, 0, 0.1);

                        &:focus {
                            outline: none;
                        }
                    }
                }

                &_sub {
                    width: 10%;
                }
            }







        }

        &_action {
            display: flex;
            flex-direction: column;
            width: 100%;
            justify-content: center;
            align-items: center;

            @include respond(phone) {
                margin-top: 20px;
                text-align: center;
            }

            .confirm_reload {
                width: 100%;
                margin-bottom: 10px;
                display: flex;
                justify-content: space-between;
                align-items: center;
            }

            .final_action {
                display: flex;
                flex-direction: column;
                justify-content: center;
                align-items: center;
                width: 90%;

                p {
                    text-align: center;
                }
            }

            input[type='submit'] {
                padding: 10px;
                width: 200px;
                background: #47C56C;
                border: none;
                outline: none;
                cursor: pointer;
                border-radius: 5px;
                color: #fff;
                font-family: 'DM Sans';
                margin-top: 15px;
            }


        }

    }
}

.no_offer {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 100px 0;

    p {
        font-size: 20px;
    }

}