@import './../../style/abstracts/mixins';

.review_writing_form {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    flex-direction: column;
    margin-top: 30px;


    &_header {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        text-align: center;


        @include respond(phone) {
            margin-right: 0px;
        }
    }

    &_inputs {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-direction: column;

        &_top {
            background: #fff;
            width: 400px;
            padding: 20px;
            box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
            border-radius: 10px;
            display: flex;
            flex-direction: column;
            margin: 15px 0;

            @include respond(phone) {
                width: 300px;
            }

            &-fromto {
                width: 100%;
                display: flex;
                justify-content: space-between;
                margin-bottom: 15px;

                >* {
                    width: 49%;
                }

            }

            &-dater {
                width: 100%;
                display: flex;
                justify-content: space-between;

                >* {
                    width: 50%;
                }
            }
        }

        &_bottom {
            width: 400px;
            background: #fff;
            padding: 20px;
            box-shadow: 0px 10px 15px -3px rgba(0, 0, 0, 0.1);
            border-radius: 10px;
            display: flex;
            justify-content: center;
            flex-direction: column;

            @include respond(phone) {
                width: 300px;
            }

            &_star {
                margin-bottom: 30px;
            }

            &_review {
                margin-bottom: 30px;

                textarea {
                    font-family: 'DM Sans';
                    padding: 10px;
                    border: 1px solid #EAEAEA;
                    border-radius: 6px;
                    width: 95%;

                    &:focus {
                        outline: none;
                    }
                }
            }

            &_note {
                margin-bottom: 10px;

                p {
                    font-style: italic;
                    font-size: 12px;
                }
            }


            &_action {

                input[type='submit'] {
                    padding: 10px;
                    border: none;
                    border-radius: 6px;
                    background: #002661;
                    color: #fff;
                    font-family: 'DM Sans';
                    width: 100%;
                    font-size: 15px;
                    cursor: pointer;
                }

                input[type='submit']:disabled {

                    background: #ddd;
                    color: #000;
                    cursor: not-allowed;
                }
            }
        }


    }
}