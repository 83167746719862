@import './../../style/abstracts/mixins';

.enquiries_wrapper {
    display: flex;
    flex-wrap: wrap;
}

.enquiries_action_wrapper {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 50px;
}

.no_enquiries {
    width: 100%;
    justify-content: center;
    align-items: center;
    display: flex;


}