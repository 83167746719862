@import '../../style/abstracts/mixins';

.admin_support {
    width: 100%;
    border-radius: 10px;
    overflow: hidden;
    background-color: #ecf4ff;


    @include respond(phone) {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
}

.admin_support-header {
    background: #002661;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    padding: 15px;
    width: 98%;

    @include respond(phone) {
        width: 95%;
    }

    p {
        color: white;
    }

    .cu_phone {
        margin-right: 20px;

        @include respond(phone) {
            margin-right: 0px;
            margin-bottom: 5px;
        }
    }

    &_cu {
        display: flex;
        flex-direction: row;

        @include respond(phone) {
            flex-direction: column;
            align-items: flex-end;
        }
    }
}

.admin_support-body {
    padding: 10px;
    display: flex;
    flex-direction: column;


    &--h {
        display: flex;
        flex-direction: row;
        justify-content: center;
        align-items: center;
        padding: 5px 0;

        p {
            color: #747474;
        }
    }

    &--c {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
        align-items: flex-start;
        margin-top: 15px;
        height: calc(100dvh - 400px);
        min-height: 300px;
        overflow-y: scroll;

        @include respond(phone) {}

        &_date {
            width: 100%;
            display: flex;
            justify-content: center;
            align-items: center;
            margin: 10px 0;

            p {
                color: #747474;
            }
        }

        &_left {
            display: flex;
            flex-direction: column;
            width: 70%;
            margin-bottom: 20px;

            @include respond(phone) {
                width: 97%;
            }



            .conv_message {
                display: flex;
                justify-content: flex-start;
                align-items: flex-end;

                pre {
                    white-space: pre-wrap;
                    white-space: -moz-pre-wrap;
                    white-space: -o-pre-wrap;
                    padding: 15px;
                    background-color: #FFF;
                    border-radius: 10px;
                    border-bottom-left-radius: 0;
                    line-height: 1.4;
                    color: #002661;
                    font-size: 16px;
                    font-family: "DM Sans";


                    @include respond(phone) {
                        font-size: 14px;
                    }
                }
            }

            .conv_time {
                display: flex;
                justify-content: flex-start;
                align-items: center;
                margin-left: 40px;

                p {
                    color: #747474;
                    margin-top: 5px;
                }
            }


        }



        &_right {
            display: flex;
            flex-direction: column;
            align-self: flex-end;
            width: 70%;
            margin-bottom: 20px;
            margin-right: 10px;

            @include respond(phone) {
                width: 87%;
            }

            .conv_message {
                display: flex;
                justify-content: flex-end;
                align-items: center;

                pre {
                    white-space: pre-wrap;
                    white-space: -moz-pre-wrap;
                    white-space: -o-pre-wrap;
                    padding: 15px;
                    background-color: #D3E4FF;
                    border-radius: 10px;
                    border-bottom-right-radius: 0;
                    line-height: 1.4;
                    color: #002661;
                    font-size: 16px;
                    font-family: "DM Sans";


                    @include respond(phone) {
                        font-size: 14px;
                    }
                }
            }

            .conv_time {
                display: flex;
                justify-content: flex-end;
                align-items: center;

                p {
                    color: #747474;
                    margin-top: 5px;
                }
            }
        }
    }

    &--i {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: center;
        justify-content: center;


        &_inp {
            width: 80%;
            margin-right: 40px;
            padding-top: 10px;

            @include respond(phone) {
                margin-right: 30px;
            }

            textarea {
                width: 100%;
                border: none;
                border-radius: 5px;
                font-family: inherit;
                padding: 10px;
                box-shadow: -5px 4px 15px 3px rgba(0, 0, 0, 0.1);

                &:focus {
                    outline: none;
                }
            }
        }

        &_sub {
            width: 10%;
        }
    }
}