@import '../../style/abstracts/mixins';

.company_review_selector {
    display: flex;
    align-items: center;
    box-shadow: -5px 4px 15px 3px rgba(0, 0, 0, 0.1);
    border-radius: 10px;
    padding: 10px;
    width: 280px;
    margin-top: 10px;
    justify-content: space-between;
    background-color: #D5E7F7;


    @include respond(phone) {
        width: 100%;
    }

    &_info {
        display: flex;
        align-items: center;
    }

    &_action {
        border-radius: 10px;
        padding: 5px;
        cursor: pointer;


    }



    .business_info {

        p {
            margin: 0;
        }

        .business_name {
            font-size: 20px;
            width: 180px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
        }
    }
}