@import '../../style/abstracts/mixins';

.reviews_cards_item {
    width: 320px;
    max-width: 350px;
    border-radius: 10px;
    box-shadow: -5px 4px 15px 3px rgba(0, 0, 0, 0.1);
    padding: 15px;
    margin-right: 20px;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    margin-top: 20px;
    background-color: #fff;

    @include respond(phone) {
        width: 100%;
        margin-right: 0px;
    }

    .review_by {
        margin-bottom: 10px;
    }

    .title_logo {
        display: flex;
        align-items: center;
        justify-content: space-between;
        margin-bottom: 10px;

        .logo_comp {
            display: flex;
            align-items: center;

            &_star {
                .business_name {
                    font-size: 18px;
                    font-weight: bold;
                }

                .business_id {
                    font-size: 14px;
                }
            }
        }

        p {
            margin: 0px;
        }
    }

    .content_wrapper {
        p {
            margin: 10px 0px;
        }

    }

    .rating_wrapper {
        display: flex;
        align-items: center;
        justify-content: space-between;

        .rating_star {
            display: flex;
            align-items: center;

            .rating_status {
                margin-left: 10px;
            }
        }

        p {
            margin-right: 5px;
            font-size: 14px;
            margin: 0px;
        }
    }

}